import { ProductMutation } from '@/store/mutationTypes';

export default {
  [ProductMutation.ADD](state, item) {
    state.products.unshift(item);
  },
  [ProductMutation.SET](state, products) {
    state.products = products;
  },
  [ProductMutation.UPDATE](state, product) {
    const index = state.products.findIndex((el) => el.id === product.id);
    Object.assign(state.products[index], product);
  },
  [ProductMutation.REMOVE](state, id) {
    const products = state.products.filter((el) => el.id !== id);
    state.products = products;
  },
};
