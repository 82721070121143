import state from './moduleBrandState';
import mutations from './moduleBrandMutations';
import actions from './moduleBrandActions';
import getters from './moduleBrandGetters';

export default {
  isRegistered: false,
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
