<template>
  <vs-sidebar
    click-not-close
    position-right
    parent="body"
    default-index="1"
    color="primary"
    class="vs-sidebar__wrapper"
    spacer
    v-model="isActive"
  >
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ titleType }} PRODUCT</h4>
      <feather-icon
        icon="XIcon"
        @click.stop="isActive = false"
        class="cursor-pointer"
      />
    </div>

    <vs-divider class="mb-0" />

    <component
      :is="scrollbarTag"
      :settings="settings"
      class="vs-sidebar__inner"
    >
      <!-- Product Image -->
      <template v-if="dataPictureUrl">
        <!-- Image Container -->
        <div
          class="img-container w-64 mx-auto flex items-center justify-center"
        >
          <img :src="dataPictureUrl" alt="img" class="responsive" />
        </div>

        <!-- Image upload Buttons -->
        <div class="modify-img flex justify-between mt-5">
          <input
            type="file"
            class="hidden"
            ref="updateImgInput"
            @change="updatePicture"
            accept="image/*"
          />
          <vs-button
            class="mr-4"
            type="flat"
            @click="$refs.updateImgInput.click()"
          >
            Update Image
          </vs-button>
          <vs-button type="flat" color="#999" @click="handleRemovePicture">
            Remove Image
          </vs-button>
        </div>
      </template>

      <!-- NAME -->
      <div>
        <vs-label text="Name" />
        <vs-input
          v-model="dataName"
          class="w-full"
          name="name"
          v-validate="'required'"
        />
        <span class="text-danger text-sm" v-show="errors.has('name')">{{
          errors.first('name')
        }}</span>
      </div>

      <!-- BRAND -->
      <div class="mt-4">
        <vs-label text="Brand" />
        <v-select
          :options="brandList"
          :clearable="false"
          v-model="dataBrand"
          name="brand"
        />
        <span class="text-danger text-sm" v-show="errors.has('brand')">{{
          errors.first('brand')
        }}</span>
      </div>

      <!-- CATEGORY -->
      <div class="mt-4">
        <vs-label text="Category" />
        <v-select
          :options="categoryList"
          :clearable="false"
          v-model="dataCategory"
          name="category"
        />
        <span class="text-danger text-sm" v-show="errors.has('category')">{{
          errors.first('category')
        }}</span>
      </div>

      <div v-if="!dataPictureUrl" class="upload-img mt-4">
        <input
          type="file"
          class="hidden"
          ref="uploadImgInput"
          @change="updatePicture"
          accept="image/*"
        />
        <vs-button @click="$refs.uploadImgInput.click()"
          >Upload Image</vs-button
        >
      </div>

      <!-- BUTTONS -->
      <sidebar-buttons
        :valid="isFormValid"
        @click="handleSubmit"
        @cancel="handleClose"
      />
    </component>
  </vs-sidebar>
</template>

<script>
import vSelect from 'vue-select';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { mapActions, mapState } from 'vuex';

import {
  BrandAction,
  ProductCategoryAction,
  ProductAction,
} from '@/store/actionTypes';
import moduleBrand from '@/store/brands/moduleBrand';
import moduleProductCategory from '@/store/product-categories/moduleProductCategory';

import sidebarMixin from '@/mixins/sidebarMixin';

import '@/assets/scss/app/pages/sidebar.scss';

export default {
  mixins: [sidebarMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
  },
  data() {
    return {
      dataId: null,
      dataName: '',
      dataCategory: null,
      dataPictureUrl: null,
      dataPictureFile: null,
      dataBrand: null,
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const { id, pictureUrl, name, brand, category } = JSON.parse(
          JSON.stringify(this.data)
        );

        this.dataId = id;
        this.dataCategory = this.getCategoryById(category.id);
        this.dataPictureUrl = pictureUrl;
        this.dataPictureFile = null;
        this.dataName = name;
        this.dataBrand = this.getBrandById(brand.id);

        this.initValues();
      }
    },
  },
  computed: {
    ...mapState('brand', { brands: ({ brands }) => brands }),
    ...mapState('product-category', {
      productCategories: ({ productCategories }) => productCategories,
    }),

    isFormValid() {
      return (
        !this.errors.any() &&
        this.dataName &&
        this.dataCategory &&
        this.dataBrand
      );
    },
    brandList() {
      const choices = this.brands.map(({ id, name }) => ({
        label: name,
        value: id,
      }));

      return choices;
    },
    categoryList() {
      const choices = this.productCategories.map(({ id, name }) => ({
        label: name,
        value: id,
      }));

      return choices;
    },
  },
  methods: {
    ...mapActions('product', [
      ProductAction.add,
      ProductAction.update,
      ProductAction.removePicture,
    ]),
    ...mapActions('brand', [BrandAction.fetch]),
    ...mapActions('product-category', [ProductCategoryAction.fetch]),

    registerStoreModules() {
      if (!moduleBrand.isRegistered) {
        this.$store.registerModule('brand', moduleBrand);
        moduleBrand.isRegistered = true;
      }

      if (!moduleProductCategory.isRegistered) {
        this.$store.registerModule('product-category', moduleProductCategory);
        moduleProductCategory.isRegistered = true;
      }
    },
    initValues() {
      if (this.data.id) return;

      this.dataId = null;
      this.dataName = '';
      this.dataCategory = null;
      this.dataBrand = null;
      this.dataPictureUrl = null;
      this.dataPictureFile = null;
    },
    async handleSubmit() {
      const isValid = await this.$validator.validateAll();

      if (!isValid) return;

      const pictureForm = new FormData();
      pictureForm.append('picture_file', this.dataPictureFile);

      const obj = {
        id: this.dataId,
        name: this.dataName,
        pictureFile: pictureForm,
        category: this.dataCategory.value,
        brand: this.dataBrand.value,
      };

      this.$vs.loading();

      if (this.shouldUpdate) {
        try {
          await this.updateProduct(obj);

          this.notifySuccess(
            'Product updated',
            'The product has been successfully updated.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with updating a product.');
        }
      } else {
        delete obj.id;

        try {
          await this.addProduct(obj);

          this.notifySuccess(
            'Product added',
            'The product has been successfully added.'
          );
        } catch (error) {
          this.notifyError(error, 'Problem with adding a product.');
        }
      }

      this.$vs.loading.close();
      this.$emit('close-sidebar');
      this.initValues();
    },
    updatePicture(input) {
      if (input.target.files && input.target.files[0]) {
        const picture = input.target.files[0];
        const reader = new FileReader();

        this.dataPictureFile = picture;
        reader.onload = (e) => {
          this.dataPictureUrl = e.target.result;
        };
        reader.readAsDataURL(picture);
      }
    },
    async handleRemovePicture() {
      if (!this.data.id) {
        this.dataPictureUrl = null;
        this.dataPictureFile = null;

        return;
      }

      try {
        await this.removeProductPicture(this.data.id);

        this.dataPictureUrl = null;
        this.dataPictureFile = null;
      } catch (error) {
        this.notifyError(error, 'Problem with deleting a photo.');
      }
    },
    getBrandById(id) {
      return this.brandList.find((el) => el.value === id);
    },
    getCategoryById(id) {
      return this.categoryList.find((el) => el.value === id);
    },
  },
  async created() {
    this.registerStoreModules();

    this.$vs.loading();

    try {
      const brands = this.fetchBrands();
      const categories = this.fetchProductCategories();

      await Promise.all([brands, categories]);
    } catch (error) {
      this.notifyError(error, 'Problem with loading data.');
    }

    this.$vs.loading.close();
  },
};
</script>
