import { BrandMutation } from '@/store/mutationTypes';

export default {
  [BrandMutation.ADD](state, item) {
    state.brands.unshift(item);
  },
  [BrandMutation.SET](state, brands) {
    state.brands = brands;
  },
  [BrandMutation.UPDATE](state, brand) {
    const index = state.brands.findIndex((el) => el.id === brand.id);
    Object.assign(state.brands[index], brand);
  },
  [BrandMutation.REMOVE](state, id) {
    const brands = state.brands.filter((el) => el.id !== id);
    state.brands = brands;
  },
};
