import axios from '@/axios';
import { BrandAction } from '@/store/actionTypes';
import { BrandMutation } from '@/store/mutationTypes';

export default {
  [BrandAction.add]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .post(`/brands`, item)
        .then((response) => {
          commit(
            BrandMutation.ADD,
            Object.assign(item, { id: response.data.id })
          );
          resolve(response);
        })
        .catch(reject);
    });
  },
  [BrandAction.fetch]({ commit }) {
    return new Promise((resolve, reject) => {
      return axios
        .get(`/brands`)
        .then((response) => {
          commit(BrandMutation.SET, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [BrandAction.update]({ commit }, item) {
    return new Promise((resolve, reject) => {
      return axios
        .put(`/brands/${item.id}`, item)
        .then((response) => {
          commit(BrandMutation.UPDATE, response.data);
          resolve(response);
        })
        .catch(reject);
    });
  },
  [BrandAction.remove]({ commit }, id) {
    return new Promise((resolve, reject) => {
      return axios
        .delete(`/brands/${id}`)
        .then((response) => {
          commit(BrandMutation.REMOVE, id);
          resolve(response);
        })
        .catch(reject);
    });
  },
};
